<template>
	<div>
		<div class="nopadding">
			<div style="padding: 30px 50px;width: 800px;margin: 50px auto;box-shadow: 0 0 10px rgba(0,0,0,.1);"
				 v-if="googlebind">
				<el-alert :title="title" type="success" style="margin-bottom:20px;"></el-alert>
				<!-- <el-form :model="form" :rules="rules" ref="dialogForm" label-width="120px"
						 label-position="left">
					<el-form-item label="验证码" prop="code">
						<el-input v-model="form.code" clearable placeholder="请输入验证码"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :loading="isSaveing" @click="submit1()">立即解绑</el-button>
					</el-form-item>
				</el-form> -->
			</div>
			<div v-else style="padding: 30px 50px;width: 800px;margin: 50px auto;box-shadow: 0 0 10px rgba(0,0,0,.1);">
				<el-alert title="谷歌验证器绑定，点击图片可刷新绑定二维码！" type="error" style="margin-bottom:20px;"></el-alert>
				<div class="no_wrap">
					<div class="qr_wrap" @click="qr">
						<img :src="qrCodeUrl" alt="">
					</div>
					<div class="tishi_wrap">
						<p style="line-height: 30px;">1、请前往appstore【苹果】或各大应用市场【安卓】搜索Google Authenticator<br> 2、扫描上方二维码，扫描成功后请点击下方按钮</p>
						<el-button style="width: 100%;margin-top: 20px" type="primary" @click="add">扫码成功，请点击这里完成绑定</el-button>
					</div>
				</div>
			</div>
		</div>
		<div>
			<el-dialog title="绑定" :visible.sync="visible" :modal-append-to-body="false" width="30%" center>
				<el-form :model="form" :rules="rules" ref="dialogForm" label-width="100px"
						 label-position="left">
					<el-form-item label="验证码" prop="code">
						<el-input v-model="form.code" clearable  placeholder="请输入谷歌验证器上的验证码"></el-input>
					</el-form-item>
				</el-form>
				<template #footer>
					<el-button @click="visible=false">我在想想</el-button>
					<el-button type="primary" :loading="isSaveing" @click="submit()">确定绑定</el-button>
				</template>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {getGoogleInfo,bindGoogle,center} from "@/api/settlement";
export default {
	name: 'googleValidator',
	data() {
		return {
			title: '您已经成功绑定,如需解绑请在下方操作。',
			//表单数据
			form: {
				code: "",
			},
			//验证规则
			rules: {
				code: [
					{required: true, message: '请输入验证码'}
				],
			},
			typeList: [],
			isSaveing: false,
			googlebind: '',
			qrCodeUrl:'',
			secret:'',
			visible:false
		}
	},
	mounted() {
		this.info1()
	},
	methods: {
		//添加
		add(){
			this.visible = true
		},
		//本地更新数据
		handleSaveSuccess(data, mode){
			if(mode=='add'){
				this.info1()
			}else if(mode=='edit'){
				this.info1()
			}
		},
		async info1() {
			center()
				.then((res) => {
					this.googlebind = res.data.google
					if ( !this.googlebind ) {
						this.qr()
					}
				})
				.catch((err) => {
					this.$message.error(err.msg);
				});
		},
		async qr(){
			getGoogleInfo()
				.then((res) => {
					this.qrCodeUrl = res.data.qrCodeUrl
					this.secret = res.data.secret
				})
				.catch((err) => {
					this.$message.error(err.msg);
				});
		},
		//表单提交方法
		submit() {
			this.$refs.dialogForm.validate(async (valid) => {
				if (valid) {
					this.isSaveing = true;
					let ajax = {
						code: this.form.code,
						secret:this.secret
					};
					bindGoogle(ajax)
						.then((res) => {
							this.isSaveing = false;
							this.$message.success("绑定成功")
							this.form.code = ''
							this.info1()
							this.visible = false
						})
						.catch((err) => {
							this.isSaveing = false;
							this.$message.error(err.msg);
						});
				}
			})
		},
		submit1() {
			this.$refs.dialogForm.validate(async (valid) => {
				if (valid) {
					this.isSaveing = true;
					let ajax = {
						code: this.form.code,
					};
					bindGoogle(ajax)
						.then((res) => {
							this.isSaveing = false;
							this.$message.success("解绑成功")
							this.form.code = ''
							this.info1()
						})
						.catch((err) => {
							this.isSaveing = false;
							this.$message.error(err.msg);
						});
				}
			})
		},
	}
}
</script>

<style scoped lang="scss">
.no_wrap{
	display: flex;
	flex-direction: column;
	.qr_wrap{
		width: 200px;
		height: 200px;
		margin: 0 auto 30px auto;
		img{
			width: 100%;
			height: 100%;
		}
	}
	.tishi_wrap{
		text-align: center;
		font-size: 16px;
	}
}
</style>


